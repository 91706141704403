:root {
  --mdc-typography-font-family: Roboto, sans-serif;
  --mdc-typography-headline4-font-weight: 700;
  --mdc-theme-primary: #247BFF;

  --blusleep-border-color: rgba(6, 19, 38, 0.12);
  --bluesleep: #247BFF;
}

.bs-schedule-widget-wrapper *:not(input) {
  margin: 0;
  padding: 0;
}

.bs-schedule-widget-wrapper span {
  font-family: Poppins, Roboto, sans-serif;
  color: rgba(6, 19, 38, 0.9);
  -webkit-font-smoothing: antialiased;
}

.bs-schedule-widget-wrapper .mdc-button--outlined:not(:disabled) {
  border-color: #247BFF;
  border-color: var(--mdc-theme-primary);
}

.bs-schedule-widget-wrapper .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #247BFF;
  color: var(--mdc-theme-primary);
}

.bs-schedule-widget-wrapper .mdc-typography--headline4, 
.bs-schedule-widget-wrapper .mdc-typography--headline6, 
.bs-schedule-widget-wrapper .mdc-typography--body1 {
  text-align: center;
}

.bs-schedule-widget-wrapper .mdc-typography--headline6 {
  font-size: 18px;
}

.bs-schedule-widget-wrapper .mdc-typography--body2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #666F77;
}

.bs-schedule-widget-wrapper .mdc-typography--caption {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.bs-schedule-widget-wrapper .mdc-typography--body1 {
  font-size: 18px;
  line-height: 28px;
  color: rgba(102, 111, 119, 1);
  font-weight: 500;
}

.bs-schedule-widget-wrapper .mdc-button__label {
  font-size: 16px;
  letter-spacing: 0.45px;
  color: #FFF;
}

.bs-schedule-widget-wrapper .mdc-button:not(:disabled) {
  background-color: #247BFF;
  background-color: var(--bluesleep);
  color: #FFF;
}

.bs-schedule-widget-wrapper .mdc-button:hover {
  background-color: #1a4ed2;
  border-color: #1a4ed2;
}

.bs-schedule-widget-wrapper .mdc-button--unelevated:hover .mdc-button__ripple::before, .mdc-button:hover .mdc-button__ripple::before {
  opacity: 0;
}

/* .mdc-button__ripple:hover {
  background-color: rgba(37, 81, 202, 1);
} */

/* @media(max-width: 860px) {
  .mdc-typography--headline4 {
    font-size: 1.5rem;
  }

  .mdc-typography--caption {
    font-size: 0.8rem;
  }

  .mdc-typography--body1 {
    font-size: 0.9rem;
  }
} */
